import React from "react";
import video from '../video1.mp4'; // Ensure the video path is correct

function Contact() {
  return (
    <header className="contact-header">
      <video autoPlay loop muted className="background-video">
        <source src={video} type="video/mp4" />
      </video>
      <div className="overlay">
        <div className="row">
          {/* Contact Info */}
          <div className="col-md-6 contact-info">
            <p>Email: <a href="mailto:info@incodesera.com">info@incodesera.com</a></p>
            <p>Address: Virtually maintained. Mainly working with USA clients on small projects. If you have a genuine project and wish to work with us, please contact us at the above email. We will try to reach out to you.</p>
          </div>

          {/* Map */}
         
        </div>
      </div>
    </header>
  );
}

export default Contact;
